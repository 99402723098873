@keyframes custom-pulse {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.2;
  }
}

.custom-pulse {
  animation: custom-pulse 1.5s ease-in-out infinite;
}

@keyframes progress-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.progress-bar {
  position: relative;
  height: 10px;
  background-color: #e5e7eb; /* Couleur de fond de la barre */
  overflow: hidden;
  border-radius: 8px; /* Ajout d'une valeur de rayon de bordure */
}

.progress {
  height: 100%;
  background: linear-gradient(
    to right,
    #7c85fc,
    #db75a5
  ); /* Dégradé de couleur de gauche à droite */
  animation: progress-animation var(--animation-duration) linear infinite; /* Utilisation de la variable --animation-duration */
}

/* Ajoutez cette classe à votre fichier CSS */
.custom-gradient {
  background-image: linear-gradient(to right, #7c85fc, #db75a5);
  border: 1px solid transparent;
  border-radius: 0.8rem;
  color: #ffffff;
  border: 1px solid #ffffff;
}

/* Vous pouvez également ajouter des couleurs de dégradé au survol */
.custom-gradient:hover {
  background: #ffffff;
  color: #616161;
  border: 1px solid #616161;
}

.custom-button {
  background: #1c1c1c;
  color: #ffffff;
  border-radius: 0.8rem;
  border: 1px solid #ffffff;
}

.custom-button:hover {
  background: #323232;
  color: #ffffff;
  border-radius: 0.8rem;
}

.desactivated-button {
  background: #646464;
  color: #f3f3f3;
  border-radius: 0.8rem;
  border: 1px solid #ffffff;
}

/* DISPLEX V3 */
/**************/

/* BLACK BUTTON */
.black-button {
  background: #1c1c1c;
  color: #ffffff;
  border-radius: 0.8rem;
  border: 1px solid #ffffff;
}

.black-button:hover {
  background: #323232;
  color: #ffffff;
  border-radius: 0.8rem;
}

/* RED BUTTON */
.red-button {
  background: #ad4040;
  color: #ffffff;
  border-radius: 0.8rem;
  border: 1px solid #ffffff;
}

.red-button:hover {
  background: #881b1b;
  color: #ffffff;
  border-radius: 0.8rem;
}

/* DISPLEX BUTTON */
.displex-button {
  background-image: linear-gradient(to right, #7c85fc, #db75a5);
  border: 1px solid transparent;
  border-radius: 0.8rem;
  color: #ffffff;
  border: 1px solid #ffffff;
}

/* Vous pouvez également ajouter des couleurs de dégradé au survol */
.displex-button:hover {
  background: #ffffff;
  color: #616161;
  border: 1px solid #616161;
}

/* CALENDAR */

/* Affichage pour les écrans de petite taille */
@media (max-width: 640px) {
  /* Forcer les éléments de la barre d'outils à se placer en colonne */
  .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .fc-toolbar-title {
    font-size: 1rem;
    text-align: center;
  }

  /* Centrer les groupes de boutons et les empiler en colonne */
  .fc .fc-button-group {
    display: flex;
    justify-content: center;
    gap: 0.25rem;
    flex-wrap: wrap;
  }

  /* Assure que chaque bouton prend un espace adapté pour mobile */
  .fc .fc-button {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
}
